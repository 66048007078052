<template>
    <div>
        <b-overlay :show="loading_notify" :opacity="0.0"> 
            <b-row class="align-items-center mb-3" v-if="first_load == false">
                <b-col cols="8">
                    <b-row class="align-items-center ml-1 mr-1">
                        <b-col>
                            <div style="font-size: 24px"><span class="app-local-location">{{shift_data.location_name}}</span>   -  <span class="app-local-location">{{shift_data.department_name}}</span></div>
                            <div style="font-size: 16px">{{shift_data.client_name}}</div>                        
                        </b-col>

                        <b-col cols="auto" end>
                            <div style="font-size: 14px;  font-weight: 600; text-align:right">
                                <span v-bind:class="{ dotActive: shift_data.deleted == 0, dotInactive: shift_data.deleted == 1 }"> </span> {{shift_data.shift_status_name}}<br>
                                <span class="font-weight-300">{{shift_data.location_street}} {{shift_data.location_house_number}}, {{shift_data.location_city}}</span>  
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="4">
                    <b-row class="align-items-center ml-1 mr-1">
                        <b-col>
                            <span> {{shift_data.shift_date_from }} </span><br>
                            <span class="font-weight-300"> {{ getDayNameAndWeekIndex() }}</span>
                        </b-col>
                        <b-col cols="auto" end>
                            <div class="app-local-time" v-html="getHours()"></div>
                            <div class="font-weight-300 text-right">{{shift_data.daytime_name }}</div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-else style="min-height:60px" class="mb-3"></b-row>

            <b-row class="match-height">
                <b-col class="pr-md-2 mb-sm-3 mb-md-0">
                    <b-card>
                        <b-row>
                            <b-col start >
                                <div class="app-card-tittle app-local-title-height">
                                    {{trans('employee',5)}} <span v-if="!loading_list">({{ persons_list.total }})</span>
                                </div>
                            </b-col>
                            <b-col cols="auto" end class="d-flex align-items-center ml-1">
                                <b-row class="app-switch-row">
                                    <label v-overflow-tooltip for="switch_2" class="mb-0">{{trans('placment',5)}}</label>
                                    <b-form-checkbox id="switch_2" v-model="filters.show_with_active_placement" switch class="app-switch" :disabled="loading_list"/>
                                </b-row>                            
                            </b-col>
                            <b-col cols="auto" end class="d-flex align-items-center ml-1">
                                <b-row class="app-switch-row">
                                    <label v-overflow-tooltip for="switch_3" class="mb-0">{{trans('planning-distance',209)}}</label>
                                    <b-form-checkbox id="switch_3" v-model="filters.show_with_distance" switch class="app-switch" :disabled="loading_list"/>
                                </b-row>                            
                            </b-col>
                            <b-col cols="auto" end class="d-flex align-items-center ml-1">
                                <b-row class="app-switch-row">
                                    <label v-overflow-tooltip for="switch_4" class="mb-0">{{trans('availability',207)}}</label>
                                    <b-form-checkbox id="switch_4" v-model="filters.show_with_availability" switch class="app-switch" :disabled="loading_list"/>
                                </b-row>                            
                            </b-col>

                            <b-col cols="auto" end class="d-flex align-items-center pl-0" style="z-index: 1;">
                                <app-refresh :refresh.sync="persons_list.refresh" :loading.sync="loading_list"/>
                            </b-col>
                        </b-row>
                        <app-view-table :isTopBar="false" :loading="loading_list" :items="persons_list" :fields="fields" :filters.sync="filters" :isDropdownMenu="false" :customColumns="customColumns" :customColumnsHeaders="customHeaders">
                            <template v-slot:head_change_status>
                                    <b-icon icon="plus" @click="addPersonAll()"   style="width:24px; height:24px; padding:0px; margin:0px; color: #174066; cursor:pointer"></b-icon> 
                            </template>
                            
                            <template v-slot:change_status="cell">
                                <b-icon icon="plus" @click="addPerson(cell.data.item)"   style="width:24px; height:24px; padding:0px; margin:0px; color: #174066; cursor:pointer"></b-icon> 
                            </template>

                            <template v-slot:distance="cell">
                                <span v-overflow-tooltip>{{cell.data.item.distance ? cell.data.item.distance + ' km' : ''}}</span>
                            </template>
                        </app-view-table>
                    </b-card>
                </b-col>
            </b-row>

            <b-row class="mt-4">
               <b-col class="pr-md-2 mb-sm-3 mb-md-0">
                    <b-card>
                        <app-view-table :title="trans('flexwerkers-to-notify',219)" :isTitleTotal="true" :isSettings="false" :items="persons_list_notifications" :fields="fields" :filters.sync="filters_notifications" :isDropdownMenu="false" :customColumns="customColumns" :customColumnsHeaders="customHeaders">
                                <template v-slot:head_change_status>
                                    <b-icon icon="dash" @click="removePersonAll()"   style="width:24px; height:24px; padding:0px; margin:0px; color: #174066; cursor:pointer"></b-icon> 
                                </template>

                                <template v-slot:change_status="cell">
                                    <b-icon icon="dash" @click="removePerson(cell.data.item)"  style="width:24px; height:24px; padding:0px; margin:0px; color: #174066; cursor:pointer"></b-icon> 
                                </template>

                                <template v-slot:distance="cell">
                                    <span v-overflow-tooltip>{{cell.data.item.distance ? cell.data.item.distance + ' km' : ''}}</span>
                                </template>
                        </app-view-table>
                    </b-card>
                </b-col>
            </b-row>
            

            <b-card class="mt-3">
                <div class="app-card-profile-title mb-2">
                    {{trans('settings-message',207)}}
                </div>
                <ValidationObserver ref="form"> 
                    <app-input v-model="title" :label="trans('td-title',205)" :validatorName="trans('td-title',205)" validatorRules="required" />

                    <app-memo v-model="message" :label="trans('content',182)" :validatorName="trans('content',182)" validatorRules="required" />
                </ValidationObserver>
            </b-card>
            <template #overlay>
                <div></div>
            </template>

        </b-overlay>

        <app-button class="mt-4" :loading="loading_notify" @click="sendNotification" :disabled="persons_list_notifications.list.length == 0"> {{trans('flexwerkers-to-notify',219)}} </app-button>
     </div>
</template>

<script>

import axios from '@axios';
import { ValidationObserver } from "vee-validate";
import AppAlerts from '@core/scripts/AppAlerts';
import moment from "moment";

export default {
    components: {
        ValidationObserver
    },
    mounted() {
        this.onCreateThis();
    },

    data(){
        return{
            id_shift_data_main: this.$route.params.id_shift_data_main,
            first_load:true,
            filters: {
                show_with_function:false,
                show_with_active_placement: false,
                show_with_distance: false,
                show_with_availability:false,
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                        functions: {type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: { type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        phases: { type: 'list', changed: 0, values: [], function: "getPhases", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 10,
                sortBy: "p.id",
                sortDesc: true,
                search: "",
                list_type: 1,
                changed: 0,
                remove: 0
            },

            title: "",
            message: "",
            shift_data:null,
            alert_class: new AppAlerts(),
            customHeaders: ['change_status'],
            customColumns:['change_status', 'distance'],
            filters_notifications: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                },
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                
            },

            first_load: true,

            fields: [
                { key: "change_status", label: '', thStyle: { "min-width": "46px", "max-width": "46px" }, visible: true},
                { key: "person_name", label: this.trans('full-name',3), thStyle: { width: "100%" }, visible: true, filtering: false },
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "functions" },
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "locations" },
                { key: "type_name", label: this.trans('agreement',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "types" },
                { key: "phase", label: this.trans('phase',3), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: true, filtering_name: "phases" },
                { key: "distance", label: this.trans('planning-distance',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true },
                
            ],

            persons_list_all: {
                list: [],
                total: 0
            },

            persons_list: {
                list: [],
                total: 0
            },

            persons_list_notifications: {
                list: [],
                total: 0
            },

            persons_list_notifications_all: {
                list: [],
                total: 0
            },
            loading_list: false,
            loading_notify:false,
        }
       
    },

    methods: {

        getList() {
            this.loading_list = true;
            axios
                .post("planning/offers/getPersonsForNotification", {
                    id_shift_data_main: this.id_shift_data_main,
                    filters: JSON.stringify(this.filters),
                    first_load: this.first_load
                })
                .then((res) => {
                    this.shift_data = res.data.shift_data;
                   this.filters.page = res.data.page
                   this.persons_list_all = JSON.parse(JSON.stringify( res.data.items));
                   this.persons_list = JSON.parse(JSON.stringify( res.data.items));
                   this.persons_list_all.list.forEach(person =>{
                        if(this.persons_list_notifications_all.list.some(x => x.id_person_data_main == person.id_person_data_main)){
                            this.persons_list.total--;
                            person.visible = 0;
                        }
                   })

                    if(this.first_load){
                        this.filters.filtering.functions.values = res.data.filters.filtering.functions.values;
                    }
                   
                   this.setLists();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                    this.first_load = false;
                });
        },

        sendNotification(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.alert_class.openAlertConfirmation(this.trans('sh-confirm-send-notifacation',221)).then(res => {
                        if(res == true){
                            this.loading_notify = true;
                            axios
                                .post("planning/offers/sendPushShiftManual", {
                                    ids_user_portal: JSON.stringify(this.persons_list_notifications_all.list.map(x=> x.id_user_portal)),
                                    title: this.title,
                                    message: this.message
                                })
                                .then((res) => {
                                    this.alert_class.openAlertInfo(this.trans('sh-notification-send',221)).then(res => {
                                        this.$router.push({ name: "planning-offers"});
                                    });
                                })
                                .catch((error) => {
                                    console.error(`error during request: ${error}`);
                                }).finally(() => {
                                    this.loading_notify = false;
                                });
                        }
                    });
                }
            });
        },

        setLists(){

                var temp_list_not =  JSON.parse(JSON.stringify(this.persons_list_notifications_all));
                if(this.filters_notifications.sorting.sort_direction == 2){
                    temp_list_not.list.sort((a,b) => 
                                    (a[this.filters_notifications.sorting.field_name] > b[this.filters_notifications.sorting.field_name]) ? 1 : 
                                    ((b[this.filters_notifications.sorting.field_name] > a[this.filters_notifications.sorting.field_name]) ? -1 : 0))
                }

                if(this.filters_notifications.sorting.sort_direction == 1){
                    temp_list_not.list.sort((a,b) => 
                                    (a[this.filters_notifications.sorting.field_name] < b[this.filters_notifications.sorting.field_name]) ? 1 : 
                                    ((b[this.filters_notifications.sorting.field_name] < a[this.filters_notifications.sorting.field_name]) ? -1 : 0))
                }
                if((this.filters_notifications.per_page * (this.filters_notifications.page-1))+1 > this.persons_list_notifications.total ) {
                    this.filters_notifications.page = 1;
                }

                this.persons_list_notifications.list = temp_list_not.list.slice(this.filters_notifications.per_page * (this.filters_notifications.page - 1), (this.filters_notifications.per_page * (this.filters_notifications.page - 1)) + this.filters_notifications.per_page);

                this.persons_list.list = this.persons_list_all.list.filter(x => x.visible == 1).slice(this.filters.per_page * (this.filters.page - 1), (this.filters.per_page * (this.filters.page - 1)) + this.filters.per_page);

        },

        addPerson(item){
            
            var itemAll = this.persons_list_all.list.find(x => x.id_person_data_main == item.id_person_data_main);
            if (itemAll) {
                itemAll.visible = 0;
                this.persons_list.total--;
                this.persons_list_notifications_all.list.unshift(item);
                this.persons_list_notifications.total++;
                this.setLists()
            }
            

        },

         addPersonAll(){
            
                
                var persons = [];

                var value_index = this.persons_list_all.list.map((x,i) => {
                        if(x.visible == 1){
                            return i;
                        }else{
                            return false;
                        }
                    }).filter(x => x !== false);

                let count = 0;

                for(let i = 0; i <= this.persons_list_all.list.length; i++ ){
                    if(value_index[i] != null){
                        this.persons_list_all.list[value_index[i]].visible = 0;
                        persons.push(this.persons_list_all.list[value_index[i]]);
                        count++;
                    }
                   
                }

                this.persons_list.total -= count;
                this.persons_list_notifications_all.list.unshift(...persons);
                this.persons_list_notifications.total += count;
                this.setLists()
            

        },

        removePerson(item){
            var index = this.persons_list_notifications.list.indexOf(item);
            var itemAll = this.persons_list_all.list.find(x => x.id_person_data_main == item.id_person_data_main)
            
            if (index !== -1) {
                if(itemAll){
                    itemAll.visible = 1;
                    this.persons_list.total++;
                }
                this.persons_list_notifications_all.list.splice(index, 1);
                this.persons_list_notifications.total--;
                this.setLists()
            }
        },

        removePersonAll(){
            var persons = [];

            var removeItems = this.persons_list_notifications_all.list.splice(0);
            let count=0
           removeItems.forEach((item) => {
                if(this.persons_list_all.list.find(x => x.id_person_data_main == item.id_person_data_main)){
                    count++;
                    this.persons_list_all.list.find(x => x.id_person_data_main == item.id_person_data_main).visible = 1;
                }
           })

            this.persons_list.total += count;
            this.persons_list_notifications.total -= removeItems.length;
            this.setLists();
        },

        getHours() { 
            
            var date_from = new Date(this.shift_data.db_shift_date_from);
            var date_to = new Date(this.shift_data.db_shift_date_to);

            return date_from.getHours() + '<sup class="ml-1">' + date_from.getMinutes().toString().padStart(2, '0') + '</sup> - ' +
                   date_to.getHours() + '<sup class="ml-1">' + date_to.getMinutes().toString().padStart(2, '0') + '</sup> ';
        },

        getDayNameAndWeekIndex() {
            moment.locale('nl') 
            return moment(this.shift_data.db_shift_date_from).format('dddd') + ", wk " + moment(this.shift_data.db_shift_date_from).format('ww');
        },

        onCreateThis() { 

            this.getList();
            if(this.$route.params.shift_time_from){
                this.title = 'Beschikbaar op ' + this.$route.params.shift_date + ' van ' + this.$route.params.shift_time_from + ' tot ' + this.$route.params.shift_time_to + '.';
                this.message = 'We hebben een urgente dienst die vervuld moet worden bij ' + this.$route.params.client_name + ' ' + this.$route.params.department_name + '. Wil jij deze dienst invullen?';
            }

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.per_page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

             this.$watch('filters_notifications.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.setLists();
                } 
            });

            this.$watch('filters_notifications.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.setLists();
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.phases.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.show_with_function', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.show_with_availability', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.show_with_distance', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.show_with_active_placement', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.first_load = false;
                    this.getList();
                } 
            });

           
            this.$watch('persons_list.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.first_load = false;
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                }   
            });

            this.$watch('filters.remove', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.first_load = false;
                    this.getList();
                }   
            });

             this.$watch('filters_notifications.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.setLists();
                }   
            });
        },
    }, 
}
</script>
<style scoped>
.tooltip{
    opacity: 1 !important;
}

.app-local-title-height{
    min-height: 30px;
}

.app-local-time {
    font-size: 20px;  
    font-weight: 600; 
    text-align:right
}

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>